.changePasscode{
    /* background: linear-gradient(180deg, #000000d1, #00000000,#000000c7);  */
    
    margin: auto;
    display: flex;
    justify-content: center;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 60vw;
    margin-top: 6%;
    background: #E9EDF0;
    border: 0.5px solid #FEFEFF;
    box-shadow: 10px 10px 24px rgb(166 180 200 / 70%), -12px -12px 20px rgb(255 255 255 / 80%);
    border-radius: 40px;
    /* padding: 2%; */
    padding: 5%;
   
}


.changePasscode__heading{
    font-style: normal;
    font-weight: 700;
    font-size: 1.5vw;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #646E82;
}

.changePasscode__inputs{
    background: #F5F5FA;
    width: 65%;
    padding: 2%;
    border: none;
    box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset -5px -5px 10px #ffffff;
    border-radius: 40px;
    height: 0.5vw;
    margin: 1vw 1vw;
}



.changePasscode__btn{
    font-size: 1vw;
    color: #FFFFFF;
    font-weight: 700;
    padding: 1% 6%;
    background-color: #0fd6eb;
    border: none;
    border-radius: 20px;
    background: #615AA1;
    box-shadow: -10px -10px 25px rgb(255 255 255 / 85%), 10px 10px 30px rgb(55 77 109 / 50%);
    border-radius: 50px;
    margin-top: 2%;
    cursor: pointer;
}


@media screen and (min-device-width: 0px) and (max-device-width: 1000px){
    .changePasscode{
        width: 75vw !important;
    margin-top: 10vw !important;
    height: 60vw !important;
    }

    .changePasscode__heading {
        font-size: 4vw;
        margin: 2vw 0vw;
    }
    .changePasscode__inputs {
        font-size: 3vw;
        height: 7vw !important;
        margin: 2vw 0vw;
    }

    .changePasscode__btn {
        font-size: 4vw;
        margin-top: 4%;
    }
}