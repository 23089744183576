.profile {
    background: #E9EDF0;
    border: 0.5px solid #FEFEFF;
    box-shadow: 10px 10px 24px rgb(166 180 200 / 70%), -12px -12px 20px rgb(255 255 255 / 80%);
    border-radius: 40px;
    width: 80%;
    margin: auto;
    display: flex;
    padding: 1vw;

}


.profile__leftBox {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #B5B5C3;
    padding: 1vw;
}

.profile__leftBox__personalInfo {
    display: flex;
    border-bottom: 1px solid #B5B5C4;
}

.profile__leftBox__personalInfo img {
    width: 2vw;
    object-fit: contain;
    margin-left: 1vw;
    margin-bottom: 1vw;
}


.profile__leftBox__personalInfo2__genderDiv div {
    margin-left: 2vw;
}

.profile__leftBox__personalInfo h3 {

    color: #8F8F9B;
}

.profile__leftBox__personalInfo p {
    color: #646467;

}


.profile__leftBox__personalInfo2 div {
    display: flex;
}




.profile__leftBox__personalInfo2 div {
    color: #646467;
    margin-bottom: 2%;

}


.profile__leftBox__personalInfo2__birthDiv__date {
    margin-left: 1vw;
    width: 70%;
    background: #f5f5fa;
    color: #7878ab;
    opacity: 0.6;
    box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset -5px -5px 10px #ffffff;
    border-radius: 40px;
    text-align: center;
    height: 1.4vw;
    margin-bottom: 3%;
}

.profile__leftBox__personalInfo2__birthDiv__date__p {
    margin-left: 1vw;
    font-weight: 900;
    /* width: 70%; */
    /* background: #f5f5fa; */
    color: #7878ab;
    opacity: 0.6;
    /* box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset -5px -5px 10px #ffffff; */
    border-radius: 40px;
    text-align: center;
    height: 1.4vw;
    margin-bottom: 3%;
}

.profile__leftBox__personalInfo2__genderDiv__genderDate {
    margin-left: 1vw;
    width: 70%;
    background: #f5f5fa;
    color: #7878ab;
    opacity: 0.6;
    box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset -5px -5px 10px #ffffff;
    border-radius: 40px;
    text-align: center;
    height: 1.4vw;
    margin-bottom: 3%;
}


.profile__leftBox__personalInfo2__addressDiv__address {
    padding: 2% 4% 2% 4%;
    font-size: 1vw;
    margin-left: 1.4vw;
    width: 70%;
    background: white;
    color: black;
    opacity: 0.6;
    box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset 5px 5px 10px #ffffff, ;
    border-radius: 6px;
    text-align: center;
    /* height: 1.4vw; */
    margin-bottom: 3%;
    border: none;
    border-bottom: 1px solid gray;
}

.profile__leftBox__personalInfo2__addressDiv__address__p {
    margin-left: 1.4vw;
    font-weight: 900;
    /* width: 70%; */
    /* width: 38%; */
    /* background: #f5f5fa; */
    color: #7878ab;
    opacity: 0.6;
    /* box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset -5px -5px 10px #ffffff; */
    border-radius: 24px;
    text-align: center;
    /* height: 1.4vw; */
    margin-bottom: 3%;
}


.profile__leftBox__personalInfo2 {
    margin-top: 6%;
    width: 17.5vw;

}

.profile__rightBox {
    padding: 1vw;
    width: 100%;
}

.profile__rightBox__topDiv {
    border-bottom: 1px solid #B5B5C4;
    padding-bottom: 1vw;
}

.profile__rightBox__bottomDiv {
    display: flex;
    justify-content: space-between;
}

.profile__rightBox h3 {
    color: #7878AB;
    font-weight: 800;
    font-size: 1.5vw;
}


.profile__rightBox__id {
    color: #8F8F9B;
    font-weight: 900;

}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 1.5vw !important;
}


.profile__rightBox__info__emailDiv {
    color: #646467;
    display: flex;
}


.profile__rightBox__info__mobileDiv {
    color: #646467;
    display: flex;
    margin-top: 1vw;

}

.css-xn6ruc {
    margin-bottom: 1vw !important;
}

.profile__rightBox__info__emailDiv__email {
    margin-left: 2vw;
}

.profile__rightBox__info__mobileDiv__mobile {
    margin-left: 1vw;
    width: 17%;
    /* background: #f5f5fa; */
    color: #7878ab;
    opacity: 0.6;
    /* box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset -5px -5px 10px #ffffff; */
    border-radius: 6px;
    text-align: center;
    height: 1.4vw;
    margin-bottom: 3%;
    border: 1px solid;
}

.css-xn6ruc>:not(style) {
    margin: 0px !important;
    width: 25ch !important;
    margin-top: -1vw !important;
    margin-left: 1vw !important;
}

.profile__leftBox__imgDiv img {
    border-radius: 100px;
}

.profile__rightBox__info__mobileDiv__mobile__p {
    /* margin-left: 1vw; */
    font-weight: 900;
    width: 17%;
    /* background: #f5f5fa; */
    color: #7878ab;
    opacity: 0.6;
    /* box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset -5px -5px 10px #ffffff; */
    border-radius: 40px;
    text-align: center;
    height: 1.4vw;
    margin-bottom: 3%;
}


.profile__rightBox__bottomDiv__leftBox div {
    color: #8F8F9B;
    display: flex;
    width: 100%;
    justify-content: space-between;
}


.profile__rightBox__bottomDiv__leftBox__id {
    margin-left: 2vw;
    font-weight: 900;
}


.profile__rightBox__bottomDiv__leftBox__buttons button {
    padding: 0.5vw 1.2vw;
    background: linear-gradient(315deg, #092737 -18.5%, #043844 166.67%), #E7EBF0;
    background-blend-mode: soft-light, normal;
    box-shadow: -10px -10px 25px rgb(255 255 255 / 85%), 10px 10px 30px rgb(55 77 109 / 50%);
    border-radius: 50px;
    /* margin: 1vw 0vw; */
    width: 7vw;
    font-size: 0.8vw;
    border: none;
}

.profile__rightBox__bottomDiv__leftBox__buttons__btn {
    margin: 1.1vw 1vw 0vw 0vw;
    cursor: pointer;

}

.profile__rightBox__bottomDiv__leftBox__buttons__btn:hover {
    background-color: #7878AB;
}



.profile__rightBox__bottomDiv__rightBox {
    text-align: center;
    width: 20%;
}

.profile__rightBox__bottomDiv__rightBox__heading {
    color: #8F8F9B;
    margin-top: 5%;

}



.profile__rightBox__bottomDiv__rightBox__counts {
    font-weight: 900;
    background: linear-gradient(315deg, #092737 -18.5%, #043844 166.67%), #E7EBF0;
    background-blend-mode: soft-light, normal;
    box-shadow: -10px -10px 25px rgb(255 255 255 / 85%), 10px 10px 30px rgb(55 77 109 / 50%);
    border-radius: 50px;
    color: #1584C2;
    text-align: center;
    padding: 3% 5%;
    font-size: 1vw;

}



@media screen and (min-device-width: 0px) and (max-device-width:1000px) {
    .profile {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;
        margin-top: 10%;
        border-radius: 0px;
        height: fit-content;
        box-shadow: none;
        padding-bottom: 5vw;
        padding-top: 5vw;
    }

    .profile__rightBox__id {
        display: flex;
    }

    .profile__rightBox__bottomDiv__leftBox div {
        justify-content: start;
    }

    .profile__leftBox__personalInfo2 {
        width: 100%;
        /* padding-bottom: 10vw; */
    }

    .profile__rightBox h3 {
        text-align: initial;
        color: #7878AB;
        font-weight: 800;
        font-size: 4.5vw;
    }

    .profile__leftBox {
        border-right: none;
        border-bottom: 1px solid #B5B5C3;
    }

    .profile__leftBox__personalInfo img {
        width: 9vw;
        object-fit: contain;
        margin-left: 1vw;
        margin-bottom: 1vw;
        margin-left: 4%;
    }

    .profile__rightBox__bottomDiv {
        display: flex;
        flex-direction: column;
    }

    .profile__rightBox__bottomDiv__leftBox__buttons button {
        width: 24%;
        font-size: 2.4vw;
        box-shadow: none;
        margin: 2% 1%;
        padding: 2%;
    }

    .profile__rightBox__bottomDiv__leftBox {
        border-bottom: 1px solid #B5B5C4;
        margin-top: 6%;
    }

    .profile__rightBox__bottomDiv__rightBox div {
        display: flex;
        justify-content: space-between;
        width: 60%;

    }

    .profile__rightBox__bottomDiv__rightBox__heading {
        margin-top: 0;
    }

    .profile__rightBox__bottomDiv__rightBox {
        width: 100%;
        margin-top: 6%;

    }

    .profile__leftBox__personalInfo2 div {
        color: #646467;
        margin-bottom: 2%;
        height: fit-content;
        margin-left: 1vw;
    }

    .profile__leftBox__personalInfo2__addressDiv__address {
        padding: 2% 4% 2% 4%;
        font-size: 3vw;
        margin-left: 4.4vw;
        font-weight: 800;
        width: 53%;
        background: white;
        color: black;
        opacity: 0.6;
        box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset 5px 5px 10px #ffffff, ;
        border-radius: 6px;
        text-align: center;
        height: 10vw;
        margin-bottom: 3%;
        border: none;
        border-bottom: 1px solid gray;
    }

    .profile__rightBox__bottomDiv__rightBox__counts {
        margin-left: 6%;
    }

    .profile__rightBox__bottomDiv__rightBox__heading {
        width: fit-content
    }

    .profile__rightBox__bottomDiv__rightBox__counts {
        padding: 1% 2%;
        font-size: 3vw;
        height: fit-content;
        align-items: center;
        text-align: center;
        width: 15vw;
        margin: 4px 0vw;


    }

    .profile__rightBox__topDiv {
        margin-top: 6%;
    }

    /* .balance {
        margin-top: 4%;

    } */

    .profile__rightBox__info__mobileDiv__mobile {
        width: 50%;
        height: 5.4vw;
    }

    .profile__rightBox__info__mobileDiv {
        color: #646467;
        display: flex;
        margin-top: 5vw;
        align-items: center;
    }

    .css-xn6ruc>:not(style) {
        margin: 0px !important;
        width: 25ch !important;
        margin-top: -4vw !important;
        margin-left: 2vw !important;
    }

    .profile__leftBox__personalInfo2__addressDiv__address__p {
        width: 70% !important;

    }

    .profile__rightBox__info__mobileDiv__mobile__p {
        margin-left: 1vw;
    }

}

.cardGradient{
    background-image: linear-gradient(to right, rgb(28, 39, 59), rgb(36, 59, 85));
}
.translate{
    transform: translateX(-50%) translateY(-50%);
    
}