.hide-scrollbars {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  
  .hide-scrollbars::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

  .text-shadow {
    text-shadow: 3px 3px 3px rgba(0, 0, 0, .5);
  }

  .text-shadow-red {
    text-shadow: 2px 2px 2px rgba(226, 12, 12, 0.589);
  }