@import url(https://fonts.googleapis.com/css2?family=Urbanist&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Urbanist:wght@300&family=Urbanist:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Bree+Serif&family=Rubik+Doodle+Shadow&family=Salsa&display=swap);
@keyframes stageBackground {
    0%, 10%, 90%, 100% {
      background-color: #00B6BB;
    }
  
    25%, 75% {
      background-color: #0094bd;
    }
  }
  
  @keyframes earthRotation {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes sunrise {
    0%, 10%, 90%, 100% {
      box-shadow: 0 0 0 25px #5ad6bd, 0 0 0 40px #4acead, 0 0 0 60px rgba(74, 206, 173, 0.6), 0 0 0 90px rgba(74, 206, 173, 0.3);
    }
  
    25%, 75% {
      box-shadow: 0 0 0 0 #5ad6bd, 0 0 0 0 #4acead, 0 0 0 0 rgba(74, 206, 173, 0.6), 0 0 0 0 rgba(74, 206, 173, 0.3);
    }
  }
  
  @keyframes moonOrbit {
    25% {
      transform: rotate(-60deg);
    }
  
    50% {
      transform: rotate(-60deg);
    }
  
    75% {
      transform: rotate(-120deg);
    }
  
    0%, 100% {
      transform: rotate(-180deg);
    }
  }
  
  @keyframes nightTime {
    0%, 90% {
      opacity: 0;
    }
  
    50%, 75% {
      opacity: 1;
    }
  }
  
  @keyframes hotPan {
    0%, 90% {
      background-color: #74667e;
    }
  
    50%, 75% {
      background-color: #b2241c;
    }
  }
  
  @keyframes heat {
    0%, 90% {
      box-shadow: inset 0 0 0 0 rgba(255, 255, 255, 0.3);
    }
  
    50%, 75% {
      box-shadow: inset 0 -2px 0 0 white;
    }
  }
  
  @keyframes smoke {
    0%, 50%, 90%, 100% {
      opacity: 0;
    }
  
    50%, 75% {
      opacity: 0.7;
    }
  }
  
  @keyframes fire {
    0%, 90%, 100% {
      opacity: 0;
    }
  
    50%, 75% {
      opacity: 1;
    }
  }
  
  @keyframes treeShake {
    0% {
      transform: rotate(0deg);
    }
  
    25% {
      transform: rotate(-2deg);
    }
  
    40% {
      transform: rotate(4deg);
    }
  
    50% {
      transform: rotate(-4deg);
    }
  
    60% {
      transform: rotate(6deg);
    }
  
    75% {
      transform: rotate(-6deg);
    }
  
    100% {
      transform: rotate(0deg);
    }
  }
  
  @keyframes fireParticles {
    0% {
      height: 30%;
      opacity: 1;
      top: 75%;
    }
  
    25% {
      height: 25%;
      opacity: 0.8;
      top: 40%;
    }
  
    50% {
      height: 15%;
      opacity: 0.6;
      top: 20%;
    }
  
    75% {
      height: 10%;
      opacity: 0.3;
      top: 0;
    }
  
    100% {
      opacity: 0;
    }
  }
  
  @keyframes fireLines {
    0%, 25%, 75%, 100% {
      bottom: 0;
    }
  
    50% {
      bottom: 5%;
    }
  }
  
  .scene {
    display: flex;
    margin: 0 auto 80px auto;
    justify-content: center;
    align-items: flex-end;
    width: 400px;
    height: 300px;
    position: relative;
  }
  
  .forest {
    display: flex;
    width: 75%;
    height: 90%;
    position: relative;
  }
  
  .tree {
    display: block;
    width: 50%;
    position: absolute;
    bottom: 0;
    opacity: 0.4;
  }
  
  .tree .branch {
    width: 80%;
    height: 0;
    margin: 0 auto;
    padding-left: 40%;
    padding-bottom: 50%;
    overflow: hidden;
  }
  
  .tree .branch:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    margin-left: -600px;
    border-left: 600px solid transparent;
    border-right: 600px solid transparent;
    border-bottom: 950px solid #000;
  }
  
  .tree .branch.branch-top {
    transform-origin: 50% 100%;
    animation: treeShake 0.5s linear infinite;
  }
  
  .tree .branch.branch-middle {
    width: 90%;
    padding-left: 45%;
    padding-bottom: 65%;
    margin: 0 auto;
    margin-top: -25%;
  }
  
  .tree .branch.branch-bottom {
    width: 100%;
    padding-left: 50%;
    padding-bottom: 80%;
    margin: 0 auto;
    margin-top: -40%;
  }
  
  .tree1 {
    width: 31%;
  }
  
  .tree1 .branch-top {
    transition-delay: 0.3s;
  }
  
  .tree2 {
    width: 39%;
    left: 9%;
  }
  
  .tree2 .branch-top {
    transition-delay: 0.4s;
  }
  
  .tree3 {
    width: 32%;
    left: 24%;
  }
  
  .tree3 .branch-top {
    transition-delay: 0.5s;
  }
  
  .tree4 {
    width: 37%;
    left: 34%;
  }
  
  .tree4 .branch-top {
    transition-delay: 0.6s;
  }
  
  .tree5 {
    width: 44%;
    left: 44%;
  }
  
  .tree5 .branch-top {
    transition-delay: 0.7s;
  }
  
  .tree6 {
    width: 34%;
    left: 61%;
  }
  
  .tree6 .branch-top {
    transition-delay: 0.2s;
  }
  
  .tree7 {
    width: 24%;
    left: 76%;
  }
  
  .tree7 .branch-top {
    transition-delay: 0.1s;
  }
  
  .tent {
    width: 60%;
    height: 25%;
    position: absolute;
    bottom: -0.5%;
    right: 15%;
    z-index: 1;
    text-align: right;
  }
  
  .roof {
    display: inline-block;
    width: 45%;
    height: 100%;
    margin-right: 10%;
    position: relative;
    /*bottom: 0;
    right: 9%;*/
    z-index: 1;
    border-top: 4px solid #4D4454;
    border-right: 4px solid #4D4454;
    border-left: 4px solid #4D4454;
    border-top-right-radius: 6px;
    transform: skew(30deg);
    box-shadow: inset -3px 3px 0px 0px #F7B563;
    /*background: linear-gradient(
      to bottom, 
      rgba(246,212,132,1) 0%,
      rgba(246,212,132,1) 24%,
      rgba(#F0B656,1) 25%,
      rgba(#F0B656,1) 74%,
      rgba(235,151,53,1) 75%,
      rgba(235,151,53,1) 100%
    );*/
    background: #f6d484;
  }
  
  .roof:before {
    content: "";
    width: 70%;
    height: 70%;
    position: absolute;
    top: 15%;
    left: 15%;
    z-index: 0;
    border-radius: 10%;
    background-color: #E78C20;
  }
  
  .roof:after {
    content: "";
    height: 75%;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: linear-gradient(to bottom, rgba(231, 140, 32, 0.4) 0%, rgba(231, 140, 32, 0.4) 64%, rgba(231, 140, 32, 0.8) 65%, rgba(231, 140, 32, 0.8) 100%);
  }
  
  .roof-border-left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 1%;
    height: 125%;
    position: absolute;
    top: 0;
    left: 35.7%;
    z-index: 1;
    transform-origin: 50% 0%;
    transform: rotate(35deg);
  }
  
  .roof-border-left .roof-border {
    display: block;
    width: 100%;
    border-radius: 2px;
    border: 2px solid #4D4454;
  }
  
  .roof-border-left .roof-border1 {
    height: 40%;
  }
  
  .roof-border-left .roof-border2 {
    height: 10%;
  }
  
  .roof-border-left .roof-border3 {
    height: 40%;
  }
  
  .door {
    width: 55px;
    height: 92px;
    position: absolute;
    bottom: 2%;
    overflow: hidden;
    z-index: 0;
    transform-origin: 0 105%;
  }
  
  .left-door {
    transform: rotate(35deg);
    position: absolute;
    left: 13.5%;
    bottom: -3%;
    z-index: 0;
  }
  
  .left-door .left-door-inner {
    width: 100%;
    height: 100%;
    transform-origin: 0 105%;
    transform: rotate(-35deg);
    position: absolute;
    top: 0;
    overflow: hidden;
    background-color: #EDDDC2;
  }
  
  .left-door .left-door-inner:before {
    content: "";
    width: 15%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: repeating-linear-gradient(#D4BC8B, #D4BC8B 4%, #E0D2A8 5%, #E0D2A8 10%);
  }
  
  .left-door .left-door-inner:after {
    content: "";
    width: 50%;
    height: 100%;
    position: absolute;
    top: 15%;
    left: 10%;
    transform: rotate(25deg);
    background-color: #fff;
  }
  
  .right-door {
    height: 89px;
    right: 21%;
    transform-origin: 0 105%;
    transform: rotate(-30deg) scaleX(-1);
    position: absolute;
    bottom: -3%;
    z-index: 0;
  }
  
  .right-door .right-door-inner {
    width: 100%;
    height: 100%;
    transform-origin: 0 120%;
    transform: rotate(-30deg);
    position: absolute;
    bottom: 0px;
    overflow: hidden;
    background-color: #EFE7CF;
  }
  
  .right-door .right-door-inner:before {
    content: "";
    width: 50%;
    height: 100%;
    position: absolute;
    top: 15%;
    right: -28%;
    z-index: 1;
    transform: rotate(15deg);
    background-color: #524A5A;
  }
  
  .right-door .right-door-inner:after {
    content: "";
    width: 50%;
    height: 100%;
    position: absolute;
    top: 15%;
    right: -20%;
    transform: rotate(20deg);
    background-color: #fff;
  }
  
  .floor {
    width: 80%;
    position: absolute;
    right: 10%;
    bottom: 0;
    z-index: 1;
  }
  
  .floor .ground {
    position: absolute;
    border-radius: 2px;
    border: 2px solid #4D4454;
  }
  
  .floor .ground.ground1 {
    width: 65%;
    left: 0;
  }
  
  .floor .ground.ground2 {
    width: 30%;
    right: 0;
  }
  
  .fireplace {
    display: block;
    width: 24%;
    height: 20%;
    position: absolute;
    left: 5%;
  }
  
  .fireplace:before {
    content: "";
    display: block;
    width: 8%;
    position: absolute;
    bottom: -4px;
    left: 2%;
    border-radius: 2px;
    border: 2px solid #4D4454;
    background: #4D4454;
  }
  
  .fireplace .support {
    display: block;
    height: 105%;
    width: 2px;
    position: absolute;
    bottom: -5%;
    left: 10%;
    border: 2px solid #4D4454;
  }
  
  .fireplace .support:before {
    content: "";
    width: 100%;
    height: 15%;
    position: absolute;
    top: -18%;
    left: -4px;
    border-radius: 2px;
    border: 2px solid #4D4454;
    transform-origin: 100% 100%;
    transform: rotate(45deg);
  }
  
  .fireplace .support:after {
    content: "";
    width: 100%;
    height: 15%;
    position: absolute;
    top: -18%;
    left: 0px;
    border-radius: 2px;
    border: 2px solid #4D4454;
    transform-origin: 0 100%;
    transform: rotate(-45deg);
  }
  
  .fireplace .support:nth-child(1) {
    left: 85%;
  }
  
  .fireplace .bar {
    width: 100%;
    height: 2px;
    border-radius: 2px;
    border: 2px solid #4D4454;
  }
  
  .fireplace .hanger {
    display: block;
    width: 2px;
    height: 25%;
    margin-left: -4px;
    position: absolute;
    left: 50%;
    border: 2px solid #4D4454;
  }
  
  .fireplace .pan {
    display: block;
    width: 25%;
    height: 50%;
    border-radius: 50%;
    border: 4px solid #4D4454;
    position: absolute;
    top: 25%;
    left: 35%;
    overflow: hidden;
    animation: heat 5s linear infinite;
  }
  
  .fireplace .pan:before {
    content: "";
    display: block;
    height: 53%;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: -1;
    border-top: 4px solid #4D4454;
    background-color: #74667e;
    animation: hotPan 5s linear infinite;
  }
  
  .fireplace .smoke {
    display: block;
    width: 20%;
    height: 25%;
    position: absolute;
    top: 25%;
    left: 37%;
    background-color: white;
    filter: blur(5px);
    animation: smoke 5s linear infinite;
  }
  
  .fireplace .fire {
    display: block;
    width: 25%;
    height: 120%;
    position: absolute;
    bottom: 0;
    left: 33%;
    z-index: 1;
    animation: fire 5s linear infinite;
  }
  
  .fireplace .fire:before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -4px;
    z-index: 1;
    border-radius: 2px;
    border: 1px solid #efb54a;
    background-color: #efb54a;
  }
  
  .fireplace .fire .line {
    display: block;
    width: 2px;
    height: 100%;
    position: absolute;
    bottom: 0;
    animation: fireLines 1s linear infinite;
  }
  
  .fireplace .fire .line2 {
    left: 50%;
    margin-left: -1px;
    animation-delay: 0.3s;
  }
  
  .fireplace .fire .line3 {
    right: 0;
    animation-delay: 0.5s;
  }
  
  .fireplace .fire .line .particle {
    height: 10%;
    position: absolute;
    top: 100%;
    z-index: 1;
    border-radius: 2px;
    border: 2px solid #efb54a;
    animation: fireParticles 0.5s linear infinite;
  }
  
  .fireplace .fire .line .particle1 {
    animation-delay: 0.1s;
  }
  
  .fireplace .fire .line .particle2 {
    animation-delay: 0.3s;
  }
  
  .fireplace .fire .line .particle3 {
    animation-delay: 0.6s;
  }
  
  .fireplace .fire .line .particle4 {
    animation-delay: 0.9s;
  }
  
  .time-wrapper {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
  }
  
  .time {
    display: block;
    width: 100%;
    height: 200%;
    position: absolute;
    transform-origin: 50% 50%;
    transform: rotate(270deg);
    animation: earthRotation 5s linear infinite;
  }
  
  .time .day {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20%;
    left: 40%;
    border-radius: 50%;
    box-shadow: 0 0 0 25px #5ad6bd, 0 0 0 40px #4acead, 0 0 0 60px rgba(74, 206, 173, 0.6), 0 0 0 90px rgba(74, 206, 173, 0.3);
    animation: sunrise 5s ease-in-out infinite;
    background-color: #ef9431;
  }
  
  .time .night {
    animation: nightTime 5s ease-in-out infinite;
  }
  
  .time .night .star {
    display: block;
    width: 4px;
    height: 4px;
    position: absolute;
    bottom: 10%;
    border-radius: 50%;
    background-color: #fff;
  }
  
  .time .night .star-big {
    width: 6px;
    height: 6px;
  }
  
  .time .night .star1 {
    right: 23%;
    bottom: 25%;
  }
  
  .time .night .star2 {
    right: 35%;
    bottom: 18%;
  }
  
  .time .night .star3 {
    right: 47%;
    bottom: 25%;
  }
  
  .time .night .star4 {
    right: 22%;
    bottom: 20%;
  }
  
  .time .night .star5 {
    right: 18%;
    bottom: 30%;
  }
  
  .time .night .star6 {
    right: 60%;
    bottom: 20%;
  }
  
  .time .night .star7 {
    right: 70%;
    bottom: 23%;
  }
  
  .time .night .moon {
    display: block;
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: 22%;
    right: 33%;
    border-radius: 50%;
    transform: rotate(-60deg);
    box-shadow: 9px 9px 3px 0 white;
    filter: blur(1px);
    animation: moonOrbit 5s ease-in-out infinite;
  }
  
  .time .night .moon:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: -9px;
    left: 9px;
    border-radius: 50%;
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.05), 0 0 0 15px rgba(255, 255, 255, 0.05), 0 0 0 25px rgba(255, 255, 255, 0.05), 0 0 0 35px rgba(255, 255, 255, 0.05);
    background-color: rgba(255, 255, 255, 0.2);
  }
.navbar {
  color: white;
  background-color: #030205;
  display: flex;
  align-items: center;
  padding: 1%;
  margin: auto;
  width: 75vw;
  background: linear-gradient(180deg, #FFFFFF -17.81%, #EBF1FF 139.04%);
  box-shadow: 16px 11px 25px 2px rgb(203 204 206 / 60%);
  border-radius: 20px;
  margin: 1% auto;
  height: 4vw;
  display: flex;
  justify-content: space-between;
}

.navbar__hamburgerIcon {
  position: absolute !important;
  left: 5px !important;
  color: black !important;
  display: none !important;
}

.navbar div p {
  color: #473B8C;
  margin-left: 30%;
  text-transform: uppercase;
  font-size: 1.4vw;
}

.navbar__date {
  color: black;
}

.navbar__rightBox {
  display: flex;
  margin-left: -10%;

}

.navbar__rightBox div {
  margin: 0% 2%;

}

.referAndWalletButtons {

  margin-top: 6%;
  text-align: center;
  display: flex !important;
  width: 75% !important;
}

.referButton {
  background: linear-gradient(6.78deg, #443D85 -2.09%, #7770BD 122.05%);
  box-shadow: 8px 7px 12px 2px rgb(203 204 206 / 80%);
  border-radius: 9px !important;
  margin-top: 6%;
  text-align: center;
  display: flex !important;
  width: 8vw !important;
  color: #FFFFFF !important;
  height: -moz-fit-content;
  height: fit-content;
}

.navbar__profileImg {
  width: 96%;
  box-shadow: 8px 9px 12px 2px rgb(203 204 206 / 50%);
  border-radius: 50%;
}

.walletButton {
  height: -moz-fit-content;
  height: fit-content;
}

.navbar__search {
  align-items: center;
  display: flex;
  margin: 0% 0%;
}

.navbar__searchInput {
  width: 200px;
  border: 1px solid white;
  border-radius: 10px;
  color: white;
  background: black;
}

.navbar__searchInput::placeholder {
  color: white;
}

.navbar__button {
  background: linear-gradient(139deg,
      rgba(10, 251, 252, 1) 1%,
      rgba(12, 235, 244, 1) 9%,
      rgba(18, 192, 223, 1) 31%,
      rgba(27, 123, 190, 1) 88%,
      rgba(28, 117, 187, 1) 91%);
  color: #ffff;
  padding: 5px 10px;
  font-size: 0.8rem;
  border-radius: 10px;
  text-decoration: none;
  margin: 0% 4%;
}

.navbar__Walletbutton {
  background: linear-gradient(139deg,
      rgba(10, 251, 252, 1) 1%,
      rgba(12, 235, 244, 1) 9%,
      rgba(18, 192, 223, 1) 31%,
      rgba(27, 123, 190, 1) 88%,
      rgba(28, 117, 187, 1) 91%);
  color: #ffff;
  padding: 5px 10px;
  font-size: 0.8rem;
  border-radius: 10px;
  text-decoration: none;
  /* position: absolute;
    right: 6%; */
}

.navbar__selectOption {
  width: 150px;
  color: white;
  font-size: 20px;
  background-color: black;
}

.navbar__heading {
  margin: 0% 7%;
  font-size: 25px;
}

.hamburgerMenu {
  display: none !important;
}

.hamburgerMenu:hover {
  cursor: pointer !important;
}


.css-17fijvd {
  position: Fixed;
  top: 50%;
  left: 50%;
  right: 10%;
  transform: translate(-50%, -50%);
  width: 50%;
  /* background-image: linear-gradient(to right, #a8caba 0%, #5d4157 100%); */
  /* background: linear-gradient(to top, #30cfd0 0%, #330867 100%); */
  height: 300px;
  /* background: rgb(33 37 40) !important; */
  /* border: 2px solid #000; */
  /* box-shadow: 10px 10px 20px 10px white !important; */
  /* padding: 32px; */
  /* color: white; */
  background: #E9EDF0;
  border: 0.5px solid #FEFEFF;
  box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.7), -12px -12px 20px rgba(255, 255, 255, 0.8);
  border-radius: 40px;
}

.navbar__rightBox__date {
  display: none;
}


@media screen and (min-device-width: 0px) and (max-device-width: 1000px) {
  .navbar {
    background: none;
    margin-top: 8%;
    justify-content: space-evenly;
    box-shadow: none;
  }

  .navbar div p {
    display: none;
    font-size: 0.8rem;
  }

  .navbar__date {
    display: none;
  }

  .navbar__rightBox div {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .navbar__rightBox__date {
    display: block !important;
    margin: 0 !important;
    margin-top: 5% !important;

  }

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    /* width: fit-content !important; */
    font-size: 3vw !important;

  }


  .referButton {
    width: 30vw !important;
  }

  .navbar__profileImg {
    margin-top: 1vw;

  }

  .navbar__hamburgerIcon {
    display: block !important;
    width: 9vw !important;
    height: 11vw !important;
  }

}

.paragraph_color {
  color: black;
}

.shareModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: 200px;
  box-shadow: 24;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
}

.iconContainer {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}

.clipboardContainer {
  width: 90%;
  height: 40px;
  margin-top: 30px;
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}

.main__dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
 
  padding: 20px;
  margin-top: 40px;
  /* position: fixed; */
}

.blur {
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

.GradientProgress{
  background-image: linear-gradient(to right bottom, #507ec3, #6a81c9, #8283cd, #9986d0, #ae88d1);
}

.mainDashboard__firstDiv {
  margin-top: -4%;
  width:100%;
  grid-gap:50px;
  gap:50px;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
}

.mainDashboard__firstDiv__rightBox_details {
  display: flex;
}

.mainDashboard__firstDiv__leftBox {
  background: linear-gradient(315deg, #6b64bc -18.5%, #534c98 166.67%),
    linear-gradient(180deg, #6b64bc 0%, #514a96 100%);
  background-blend-mode: soft-light, normal;
  mix-blend-mode: normal;
  box-shadow: -10px -10px 25px rgb(255 255 255 / 85%),
    10px 10px 30px rgb(55 77 109 / 50%);
  border-radius: 15px;
  width: 20vw;
  text-align: center;
  height: 10vw;
  /* align-items: center; */
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.mainDashboard__firstDiv__leftBox p {
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  /* line-height: 75px; */
  color: #ffffff;
}

.wallet__count {
  font-size: 4vw !important;
}

.mainDashboard__firstDiv__rightBox {
  /* margin-left: 5%; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.mainDashboard__firstDiv__rightBox p {
  background: linear-gradient(315deg, #6b64bc -18.5%, #534c98 166.67%),
    linear-gradient(180deg, #6b64bc 0%, #514a96 100%);
  background-blend-mode: soft-light, normal;
  mix-blend-mode: normal;
  box-shadow: -10px -10px 25px rgb(255 255 255 / 85%),
    10px 10px 30px rgb(55 77 109 / 50%);
  border-radius: 50px;
  width: 18vw;
  padding: 2%;
  text-align: center;
  margin: 2%;
  font-size: 1.4vw;
  cursor: pointer;
}

.upgradePlan {
  box-shadow: -10px -10px 25px rgb(255 255 255 / 85%), 10px 10px 30px rgb(55 77 109 / 50%) !important;
    border-radius: 50px !important;
    width: 19vw !important;
    padding: 1% !important;
    text-align: center !important;
    /* margin: 2% !important; */
    font-size: 1.1vw !important;
    cursor: pointer !important;
    color: #6357af;
    border: 2px solid #6357af;
    /* margin-left: 1vw; */
    /* margin-bottom: 5%; */
    cursor: pointer !important;
}

.upgradePlan__modal {
  position: Fixed !important;
  top: 50% !important;
  left: 50% !important;
  right: 10% !important;
  transform: translate(-50%, -50%) !important;
  width: 50% !important;
  height: 300px !important;
  background: #E9EDF0 !important;
  border: 0.5px solid #FEFEFF !important;
  box-shadow: 10px 10px 24px rgb(166 180 200 / 70%), -12px -12px 20px rgb(255 255 255 / 80%) !important;
  border-radius: 40px !important;
}

.upgradePlan_select {
  padding: 1% 2%;
  background: #f5f5fa;
  box-shadow: -2px -2px 4px rgb(255 255 255 / 50%), 2px 2px 4px rgb(170 170 204 / 25%), 5px 5px 10px rgb(170 170 204 / 50%), -5px -5px 10px #ffffff;
  border-radius: 40px;
  height: 17%;
  font-size: 0.9vw !important;
  color: #7878ab;
  border: none;
  width: 20vw;
  margin-top: 5%;
}



.mainDashboard__firstDiv__rightBox__autoTouup {
  display: flex;
  align-items: center;
  /* justify-content: space-between;
  width:100%; */
}

.mainDashboard__firstDiv__rightBox__autoTouup__box {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.mainDashboard__firstDiv__rightBox span {
  /* color: #6357af;
  margin-left: 5%; */
}

.mainDashboard__secondDiv__boxes {
  display: flex;
  flex-direction: column;
  color: #473b8c;
  margin-top: -4%;
  justify-content: center;
  grid-gap:20px;
  gap:20px;
}

.boxCount {
  text-shadow: -1px 0 5px #473b8c;

  font-style: normal;
  font-weight: 800;
  font-size: 2vw;
  line-height: 9.3vw;
  color: #473b8c;
  margin-top: -6%;
}

.mainDashboard__secondDiv__boxes__firstRow {
  width:100%;

  display: flex;
  @media(max-width:1200px){
    flex-direction: column;
    }
}

.mainDashboard__secondDiv__boxes__firstRow__box {
  /* background: linear-gradient(315deg, #6b64bc -18.5%, #534c98 166.67%),
    linear-gradient(180deg, #f2f4fa 0%, #ecf1fe 100%);
  background-blend-mode: soft-light, normal;
  mix-blend-mode: normal;
  box-shadow: -10px -10px 25px rgb(255 255 255 / 85%),
    10px 10px 30px rgb(55 77 109 / 50%);
  border-radius: 40px 40px 40px 30px; */
  width: 25vw;
  text-align: center;
  margin: 10% 2%;
  height: 10vw;
  max-width: 395px;
  max-height: 180px;

}

.mainDashboard__secondDiv__boxes__firstRow__box__img {
  margin-top: -15%;
  /* box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.25),
    -10px -10px 10px rgba(255, 255, 255, 0.93), 5px 5px 10px rgba(0, 0, 0, 0.25),
    10px 10px 10px #cdcfd7; */
  border-radius: 50%;
}

.mainDashboard__secondDiv__boxes__secondRow {
  display: flex;
  margin-top: -14%;
  width:100%;
  @media(max-width:1200px){
  flex-direction: column;
  }
}

.mainDashboard__secondDiv__boxes__secondRow_box {
  /* background: linear-gradient(315deg, #6b64bc -18.5%, #534c98 166.67%),
    linear-gradient(180deg, #f2f4fa 0%, #ecf1fe 100%);
  background-blend-mode: soft-light, normal;
  mix-blend-mode: normal;
  box-shadow: -10px -10px 25px rgb(255 255 255 / 85%),
    10px 10px 30px rgb(55 77 109 / 50%);
  border-radius: 40px 40px 40px 30px; */
  width: 25vw;
  text-align: center;
  margin: 10% 2%;
  height: 10vw;
  max-width: 395px;
  max-height: 180px;

}

.mainDashboard__secondDiv__boxes__secondRow_box__img {
  margin-top: -15%;
  /* box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.25),
    -10px -10px 10px rgba(255, 255, 255, 0.93), 5px 5px 10px rgba(0, 0, 0, 0.25),
    10px 10px 10px #cdcfd7; */
  border-radius: 50%;

}

.css-1j7zkcj {
  height: -moz-fit-content !important;
  height: fit-content !important;
  border: none !important;
  border-radius: 10px !important;
}

.modalinside {
  margin: 5% 10% 5% 10%;
}

.modalinside_heading {
  color: #7878ab;
  font-size: 1.3vw;
  font-weight: 800;
}

.modalinside_input {
  background: #f5f5fa;
  /* Inner shadow 2 */
  box-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.5),
    inset 2px 2px 4px rgba(170, 170, 204, 0.25),
    inset 5px 5px 10px rgba(170, 170, 204, 0.5), inset -5px -5px 10px #ffffff;
  border-radius: 40px;
  border: none !important;
  height: 3vw;
  width: 40vw;
  text-align: center;
}

.modalinside_button {
  margin: 4% 5% !important;
  padding: 2% 5% !important;
  background: #273e7b !important;
  box-shadow: -10px -10px 25px rgb(255 255 255 / 85%),
    10px 10px 30px rgb(55 77 109 / 50%) !important;
  border-radius: 50px !important;
  color: white !important;
  align-items: center;
  width: 17vw;
}

.modalinside_button1 {
  margin: 4% 5% !important;
  padding: 1.5% 12% !important;
  background: #273e7b !important;
  box-shadow: -10px -10px 25px rgb(255 255 255 / 85%),
    10px 10px 30px rgb(55 77 109 / 50%) !important;
  border-radius: 50px !important;
  color: white !important;
}

.modalDepositinside {
  margin: 5% 10% 5% 10%;
  text-align: center;
}

.modalinside_depositButton1 {
  margin: 4% 5% !important;
  padding: 1.5% 12% !important;
  /* background: linear-gradient(315deg, #092737 -18.5%, #043844 166.67%), #E7EBF0; */
  box-shadow: -10px -10px 25px rgb(255 255 255 / 85%),
    10px 10px 30px rgb(55 77 109 / 50%) !important;
  border-radius: 50px !important;
  color: #7878ab !important;
}

.modalrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.unknown {
  margin: 4% 5% !important;
  padding: 1.5% 12% !important;
  /* background: linear-gradient(315deg, #092737 -18.5%, #043844 166.67%), #E7EBF0; */
  box-shadow: -10px -10px 25px rgb(255 255 255 / 85%), 10px 10px 30px rgb(55 77 109 / 50%) !important;
  border-radius: 50px !important;
  color: #7878ab !important;
}

.main__dashboardToplogo {
  width: 100%;
}

.refferList {
  padding: 1%;
}

.main__dashboard__boxes {
  display: flex;
  width: 70%;
}

.modalinside_upgradePlanButton1 {
  margin: 4% 5% !important;
  padding: 1.5% 12% !important;
  /* background: linear-gradient(315deg, #092737 -18.5%, #043844 166.67%), #E7EBF0; */
  box-shadow: -10px -10px 25px rgb(255 255 255 / 85%), 10px 10px 30px rgb(55 77 109 / 50%) !important;
  border-radius: 50px !important;
  color: #7878ab !important;
}



.main__dashboard__boxes__forResponsive {
  display: flex;
}

.main__dashboard__boxes__forResponsive__forAlignBox4toBottom {
  display: flex;
}

.main__dashboard__leftBoxes {
  font-weight: 800;
  margin: 0% 4%;
}





.css-1w1c116 {
  margin: auto !important;

}

.css-1wtrznv {
  margin: auto !important;
}

.css-f3jnds-MuiDataGrid-columnHeaders {
  background: linear-gradient(315deg, #6b64bc -18.5%, #534c98 166.67%), linear-gradient(180deg, #6b64bc, #514a96) !important;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: white !important;
  font-weight: 800 !important;
  font-size: 0.8vw !important;
}


.main__dashboard__middleArea {
  margin: 27% 4%;
}

.main__dashboard__middleAreaLogo {
  width: 100%;
}

.animatedLogoforresponsive {
  width: 50%;
  display: none;
}

.main__dashboard__Logoforresponsive {
  width: 100%;
  display: none;
}

.main__dashboard__middleArea__box4forResponsive {
  /* display: none; */
}



.main__dashboard__rightBoxes {
  font-weight: 800;
  margin: 0% 4%;
}






@media screen and (min-device-width: 0px) and (max-device-width: 450px) {
  .main__dashboard__leftBox__heading {
    font-size: 9px !important;
    margin-top: 26px !important;
  }

  .main__dashboard__leftBox__count {
    margin-top: -10px !important;
    font-size: 10px;
  }

  .main__dashboard__middleArea__heading {
    font-size: 9px !important;
    margin-top: 26px !important;
  }

  .main__dashboard__middleArea__count {
    margin-top: -10px !important;
    font-size: 10px;
  }

  .main__dashboard__rightBox__heading {
    font-size: 9px !important;
    margin-top: 26px !important;
  }

  .main__dashboard__rightBox__count {
    margin-top: -10px !important;
    font-size: 10px;
  }

  .main__dashboard__leftBoxes__box1 {
    width: 28vw !important;
    margin: 4% 0% !important;
  }

  .main__dashboard__leftBoxes__box2 {
    width: 28vw !important;
    margin: 4% 0% !important;
  }

  .main__dashboard__leftBoxes__box3 {
    width: 28vw !important;
    margin: 4% 0% !important;
  }

  .box3Heading {
    font-size: 8px !important;
  }

  .main__dashboard__middleArea__box4forresponsive {
    margin-top: 10%;

    font-size: 12px;
    width: 28vw !important;
    margin: 4% 0% !important;
  }

  .main__dashboard__middleArea__box4forresponsive__details {
    margin-top: 10%;
    font-size: 10px;
  }

  .main__dashboard__rightBoxes__box5 {
    width: 28vw !important;
    margin: 4% 0% !important;
  }

  .main__dashboard__rightBoxes__box6 {
    width: 28vw !important;
    margin: 4% 0% !important;
  }

  .main__dashboard__rightBoxes__box7 {
    width: 28vw !important;
    margin: 4% 0% !important;
  }

  .main__dashboard__middleArea__box4forresponsive__heading {
    font-size: 8px !important;
    margin-top: 15px !important;
  }

  .main__dashboard__middleArea__box4forresponsive__count {
    font-size: 10px !important;
    margin-top: -10px !important;
  }
}

@media screen and (min-device-width: 450px) and (max-device-width: 650px) {
  .main__dashboard__leftBox__heading {
    font-size: 9px !important;
    margin-top: 16px !important;
  }

  .main__dashboard__leftBox__count {
    margin-top: -15px !important;
    font-size: 10px;
  }

  .main__dashboard__middleArea__heading {
    font-size: 9px !important;
    margin-top: 16px !important;
  }

  .main__dashboard__middleArea__count {
    margin-top: -10px !important;
    font-size: 10px !important;
  }

  .main__dashboard__rightBox__heading {
    font-size: 9px !important;
    margin-top: 16px !important;
  }

  .main__dashboard__rightBox__count {
    margin-top: -10px !important;
    font-size: 10px;
  }
}

@media screen and (min-device-width: 650px) and (max-device-width: 700px) {
  .main__dashboard__leftBox__heading {
    font-size: 9px;
    margin-top: 16px;
  }

  .main__dashboard__leftBox__count {
    font-size: 10px;
  }

  .main__dashboard__middleArea__heading {
    font-size: 9px;
    margin-top: 16px;
  }

  .main__dashboard__middleArea__count {
    font-size: 10px;
  }

  .main__dashboard__rightBox__heading {
    font-size: 9px;
    margin-top: 16px;
  }

  .main__dashboard__rightBox__count {
    font-size: 10px;
  }
}

@media screen and (min-device-width: 700px) and (max-device-width: 955px) {
  .main__dashboard__leftBox__heading {
    font-size: 9px;
    margin-top: 10px;
  }

  .main__dashboard__leftBox__count {}

  .main__dashboard__middleArea__heading {
    font-size: 9px;
    margin-top: 10px;
  }

  .main__dashboard__middleArea__count {}

  .main__dashboard__rightBox__heading {
    font-size: 9px;
    margin-top: 10px;
  }

  .main__dashboard__rightBox__count {}
}

@media screen and (min-device-width: 955px) and (max-device-width: 1170px) {
  .main__dashboard__leftBox__heading {
    font-size: 14px;
  }

  .main__dashboard__leftBox__count {}

  .main__dashboard__middleArea__heading {
    font-size: 14px;
  }

  .main__dashboard__middleArea__count {}

  .main__dashboard__rightBox__heading {
    font-size: 14px;
  }

  .main__dashboard__rightBox__count {}
}


@media screen and (min-device-width: 0px) and (max-device-width: 1000px) {
  .main__dashboard__middleArea {
    display: none;
  }

  .animatedLogoforresponsive {
    display: block;
  }



  .main__dashboard__boxes__forResponsive {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .main__dashboard__boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main__dashboard__middleArea__box4forresponsive {
    display: none;
  }

  .main__dashboard__boxes__forResponsive__forAlignBox4toBottom {
    display: flex;
  }

  .main__dashboard__middleArea__box4forresponsive__heading {
    font-size: 10px;
    margin-top: 29px;
  }
}

@media screen and (min-device-width: 0px) and (max-device-width: 1000px) {
  .modalinside_heading {
    color: #7878ab;
    font-size: 4.2vw;
    font-weight: 800;
  }

  .mainDashboard__firstDiv__leftBox {
    background-blend-mode: soft-light, normal;
    mix-blend-mode: normal;
    border-radius: 123px !important;
    width: 26vw;
    text-align: center;
    height: 21vw;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border: 16px solid#514A96;
    color: black;
    border-radius: 50% !important;
    padding: 5%;
    background: linear-gradient(315deg, #e9edf0 -18.5%, #e0e0e0 166.67%), linear-gradient(180deg, #e9edf0 0%, #c0c0c0 100%);
    box-shadow: -10px -10px 25px rgb(255 255 255 / 85%), 10px 10px 30px rgb(55 77 109 / 50%);
    margin-top: 10%;
  }

  .mainDashboard__firstDiv__rightBox_details {
    display: flex;
    align-items: center;
    text-align: center;
    margin: auto;
    justify-content: space-around;
  }
 
  .mainDashboard__firstDiv__leftBox p {
    color: #7871c9;
    font-size: 5vw;
  }

  .mainDashboard__firstDiv {
    margin-top: -4%;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    text-align: center;
    align-items: center;
  }

  .wallet__count {
    font-size: 7vw !important;

  }

  .mainDashboard__firstDiv__rightBox {
    margin-left: 0%;
    margin-top: 4%;
  }

  .mainDashboard__secondDiv__boxes {
    margin-top: 4%;
    align-items: center;
  }

  .mainDashboard__secondDiv__boxes__firstRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 14%;
  }

  .mainDashboard__secondDiv__boxes__secondRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 14%;
  }

  .mainDashboard__secondDiv__boxes__firstRow__box {
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    width: 80vw;
    height: 24vw;

    border-radius: 20px;
    margin: 2% 0%;
  }

  .mainDashboard__secondDiv__boxes__firstRow__box__img {
    width: 10vw;
    height: 10vw;
    margin: 0% 5%;
  }

  .mainDashboard__secondDiv__boxes__secondRow_box {
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    width: 80vw;
    height: 24vw;
    border-radius: 20px;
    margin: 2% 0%;
  }

  .mainDashboard__secondDiv__boxes__firstRow__box div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -15vw;
    width: 100%;
  }

  .mainDashboard__secondDiv__boxes__secondRow_box div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -15vw;

    width: 100%;
  }

  .mainDashboard__secondDiv__boxes__secondRow_box__img {
    width: 10vw;
    height: 10vw;
    margin: 0% 5%;
  }

  .boxCount {
    font-size: 5vw;
    margin-top: 0%;

  }

  .mainDashboard__firstDiv__rightBox p {
    font-size: 4vw;
    padding: 5%;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 5%;
  }

  .withdraw {
    margin-left: 5%;
  }

  .mainDashboard__firstDiv__rightBox__autoTouup {
    display: flex;
    margin-top: 4vw;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .upgradePlan {
    font-size: 4.1vw !important;
    margin-bottom: 5%;
    width: 45vw !important;

  }

  .css-1j7zkcj {
    width: 85% !important;
    padding: 2% 2% !important;
  } 

  .modalinside_input {
    height: 8vw;

    width: 55vw;
    margin: 2vw 0vw;
  }

  .modalrow {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .css-1j7zkcj {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }

  .modalinside_button {
    width: 55vw !important;
    height: 7vw;
  }

  .modalinside_button1 {
    width: 55vw !important;

  }

  .upgradePlan_select {
    margin: 4vw 0vw;
    width: 36vw;
    font-size: 3.9vw !important;
    height: 7vw;

  }

  .modalinside_upgradePlanButton1 {
    font-size: 4vw !important;
  }

  .upgradePlan_select option {
    font-size: 3vw;
    margin: 0% 2%;
  }

  .css-1wtrznv {
    height: 550px !important;
    width: 90% !important;
    margin: 10% auto !important;
  }

  .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
    font-size: 3.5vw !important;
  }

  .shareModal {
    width: 80%;
  }

  .iconContainer {
    width: 100%;
  }

  .clipboardContainer {
    width: 100%;
  }


}
.GridientBg{
  background-image: linear-gradient(to right top, #2d2e2e, #313232, #353536, #393939, #3d3d3d);
 
}
.GridientBg:hover{
  background: linear-gradient(170deg, rgba(58, 56, 56, 0.623) 0%, rgb(31, 31, 31) 100%);
}
.profile {
    background: #E9EDF0;
    border: 0.5px solid #FEFEFF;
    box-shadow: 10px 10px 24px rgb(166 180 200 / 70%), -12px -12px 20px rgb(255 255 255 / 80%);
    border-radius: 40px;
    width: 80%;
    margin: auto;
    display: flex;
    padding: 1vw;

}


.profile__leftBox {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #B5B5C3;
    padding: 1vw;
}

.profile__leftBox__personalInfo {
    display: flex;
    border-bottom: 1px solid #B5B5C4;
}

.profile__leftBox__personalInfo img {
    width: 2vw;
    object-fit: contain;
    margin-left: 1vw;
    margin-bottom: 1vw;
}


.profile__leftBox__personalInfo2__genderDiv div {
    margin-left: 2vw;
}

.profile__leftBox__personalInfo h3 {

    color: #8F8F9B;
}

.profile__leftBox__personalInfo p {
    color: #646467;

}


.profile__leftBox__personalInfo2 div {
    display: flex;
}




.profile__leftBox__personalInfo2 div {
    color: #646467;
    margin-bottom: 2%;

}


.profile__leftBox__personalInfo2__birthDiv__date {
    margin-left: 1vw;
    width: 70%;
    background: #f5f5fa;
    color: #7878ab;
    opacity: 0.6;
    box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset -5px -5px 10px #ffffff;
    border-radius: 40px;
    text-align: center;
    height: 1.4vw;
    margin-bottom: 3%;
}

.profile__leftBox__personalInfo2__birthDiv__date__p {
    margin-left: 1vw;
    font-weight: 900;
    /* width: 70%; */
    /* background: #f5f5fa; */
    color: #7878ab;
    opacity: 0.6;
    /* box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset -5px -5px 10px #ffffff; */
    border-radius: 40px;
    text-align: center;
    height: 1.4vw;
    margin-bottom: 3%;
}

.profile__leftBox__personalInfo2__genderDiv__genderDate {
    margin-left: 1vw;
    width: 70%;
    background: #f5f5fa;
    color: #7878ab;
    opacity: 0.6;
    box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset -5px -5px 10px #ffffff;
    border-radius: 40px;
    text-align: center;
    height: 1.4vw;
    margin-bottom: 3%;
}


.profile__leftBox__personalInfo2__addressDiv__address {
    padding: 2% 4% 2% 4%;
    font-size: 1vw;
    margin-left: 1.4vw;
    width: 70%;
    background: white;
    color: black;
    opacity: 0.6;
    box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset 5px 5px 10px #ffffff, ;
    border-radius: 6px;
    text-align: center;
    /* height: 1.4vw; */
    margin-bottom: 3%;
    border: none;
    border-bottom: 1px solid gray;
}

.profile__leftBox__personalInfo2__addressDiv__address__p {
    margin-left: 1.4vw;
    font-weight: 900;
    /* width: 70%; */
    /* width: 38%; */
    /* background: #f5f5fa; */
    color: #7878ab;
    opacity: 0.6;
    /* box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset -5px -5px 10px #ffffff; */
    border-radius: 24px;
    text-align: center;
    /* height: 1.4vw; */
    margin-bottom: 3%;
}


.profile__leftBox__personalInfo2 {
    margin-top: 6%;
    width: 17.5vw;

}

.profile__rightBox {
    padding: 1vw;
    width: 100%;
}

.profile__rightBox__topDiv {
    border-bottom: 1px solid #B5B5C4;
    padding-bottom: 1vw;
}

.profile__rightBox__bottomDiv {
    display: flex;
    justify-content: space-between;
}

.profile__rightBox h3 {
    color: #7878AB;
    font-weight: 800;
    font-size: 1.5vw;
}


.profile__rightBox__id {
    color: #8F8F9B;
    font-weight: 900;

}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 1.5vw !important;
}


.profile__rightBox__info__emailDiv {
    color: #646467;
    display: flex;
}


.profile__rightBox__info__mobileDiv {
    color: #646467;
    display: flex;
    margin-top: 1vw;

}

.css-xn6ruc {
    margin-bottom: 1vw !important;
}

.profile__rightBox__info__emailDiv__email {
    margin-left: 2vw;
}

.profile__rightBox__info__mobileDiv__mobile {
    margin-left: 1vw;
    width: 17%;
    /* background: #f5f5fa; */
    color: #7878ab;
    opacity: 0.6;
    /* box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset -5px -5px 10px #ffffff; */
    border-radius: 6px;
    text-align: center;
    height: 1.4vw;
    margin-bottom: 3%;
    border: 1px solid;
}

.css-xn6ruc>:not(style) {
    margin: 0px !important;
    width: 25ch !important;
    margin-top: -1vw !important;
    margin-left: 1vw !important;
}

.profile__leftBox__imgDiv img {
    border-radius: 100px;
}

.profile__rightBox__info__mobileDiv__mobile__p {
    /* margin-left: 1vw; */
    font-weight: 900;
    width: 17%;
    /* background: #f5f5fa; */
    color: #7878ab;
    opacity: 0.6;
    /* box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset -5px -5px 10px #ffffff; */
    border-radius: 40px;
    text-align: center;
    height: 1.4vw;
    margin-bottom: 3%;
}


.profile__rightBox__bottomDiv__leftBox div {
    color: #8F8F9B;
    display: flex;
    width: 100%;
    justify-content: space-between;
}


.profile__rightBox__bottomDiv__leftBox__id {
    margin-left: 2vw;
    font-weight: 900;
}


.profile__rightBox__bottomDiv__leftBox__buttons button {
    padding: 0.5vw 1.2vw;
    background: linear-gradient(315deg, #092737 -18.5%, #043844 166.67%), #E7EBF0;
    background-blend-mode: soft-light, normal;
    box-shadow: -10px -10px 25px rgb(255 255 255 / 85%), 10px 10px 30px rgb(55 77 109 / 50%);
    border-radius: 50px;
    /* margin: 1vw 0vw; */
    width: 7vw;
    font-size: 0.8vw;
    border: none;
}

.profile__rightBox__bottomDiv__leftBox__buttons__btn {
    margin: 1.1vw 1vw 0vw 0vw;
    cursor: pointer;

}

.profile__rightBox__bottomDiv__leftBox__buttons__btn:hover {
    background-color: #7878AB;
}



.profile__rightBox__bottomDiv__rightBox {
    text-align: center;
    width: 20%;
}

.profile__rightBox__bottomDiv__rightBox__heading {
    color: #8F8F9B;
    margin-top: 5%;

}



.profile__rightBox__bottomDiv__rightBox__counts {
    font-weight: 900;
    background: linear-gradient(315deg, #092737 -18.5%, #043844 166.67%), #E7EBF0;
    background-blend-mode: soft-light, normal;
    box-shadow: -10px -10px 25px rgb(255 255 255 / 85%), 10px 10px 30px rgb(55 77 109 / 50%);
    border-radius: 50px;
    color: #1584C2;
    text-align: center;
    padding: 3% 5%;
    font-size: 1vw;

}



@media screen and (min-device-width: 0px) and (max-device-width:1000px) {
    .profile {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;
        margin-top: 10%;
        border-radius: 0px;
        height: -moz-fit-content;
        height: fit-content;
        box-shadow: none;
        padding-bottom: 5vw;
        padding-top: 5vw;
    }

    .profile__rightBox__id {
        display: flex;
    }

    .profile__rightBox__bottomDiv__leftBox div {
        justify-content: start;
    }

    .profile__leftBox__personalInfo2 {
        width: 100%;
        /* padding-bottom: 10vw; */
    }

    .profile__rightBox h3 {
        text-align: initial;
        color: #7878AB;
        font-weight: 800;
        font-size: 4.5vw;
    }

    .profile__leftBox {
        border-right: none;
        border-bottom: 1px solid #B5B5C3;
    }

    .profile__leftBox__personalInfo img {
        width: 9vw;
        object-fit: contain;
        margin-left: 1vw;
        margin-bottom: 1vw;
        margin-left: 4%;
    }

    .profile__rightBox__bottomDiv {
        display: flex;
        flex-direction: column;
    }

    .profile__rightBox__bottomDiv__leftBox__buttons button {
        width: 24%;
        font-size: 2.4vw;
        box-shadow: none;
        margin: 2% 1%;
        padding: 2%;
    }

    .profile__rightBox__bottomDiv__leftBox {
        border-bottom: 1px solid #B5B5C4;
        margin-top: 6%;
    }

    .profile__rightBox__bottomDiv__rightBox div {
        display: flex;
        justify-content: space-between;
        width: 60%;

    }

    .profile__rightBox__bottomDiv__rightBox__heading {
        margin-top: 0;
    }

    .profile__rightBox__bottomDiv__rightBox {
        width: 100%;
        margin-top: 6%;

    }

    .profile__leftBox__personalInfo2 div {
        color: #646467;
        margin-bottom: 2%;
        height: -moz-fit-content;
        height: fit-content;
        margin-left: 1vw;
    }

    .profile__leftBox__personalInfo2__addressDiv__address {
        padding: 2% 4% 2% 4%;
        font-size: 3vw;
        margin-left: 4.4vw;
        font-weight: 800;
        width: 53%;
        background: white;
        color: black;
        opacity: 0.6;
        box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset 5px 5px 10px #ffffff, ;
        border-radius: 6px;
        text-align: center;
        height: 10vw;
        margin-bottom: 3%;
        border: none;
        border-bottom: 1px solid gray;
    }

    .profile__rightBox__bottomDiv__rightBox__counts {
        margin-left: 6%;
    }

    .profile__rightBox__bottomDiv__rightBox__heading {
        width: -moz-fit-content;
        width: fit-content
    }

    .profile__rightBox__bottomDiv__rightBox__counts {
        padding: 1% 2%;
        font-size: 3vw;
        height: -moz-fit-content;
        height: fit-content;
        align-items: center;
        text-align: center;
        width: 15vw;
        margin: 4px 0vw;


    }

    .profile__rightBox__topDiv {
        margin-top: 6%;
    }

    /* .balance {
        margin-top: 4%;

    } */

    .profile__rightBox__info__mobileDiv__mobile {
        width: 50%;
        height: 5.4vw;
    }

    .profile__rightBox__info__mobileDiv {
        color: #646467;
        display: flex;
        margin-top: 5vw;
        align-items: center;
    }

    .css-xn6ruc>:not(style) {
        margin: 0px !important;
        width: 25ch !important;
        margin-top: -4vw !important;
        margin-left: 2vw !important;
    }

    .profile__leftBox__personalInfo2__addressDiv__address__p {
        width: 70% !important;

    }

    .profile__rightBox__info__mobileDiv__mobile__p {
        margin-left: 1vw;
    }

}

.cardGradient{
    background-image: linear-gradient(to right, rgb(28, 39, 59), rgb(36, 59, 85));
}
.translate{
    transform: translateX(-50%) translateY(-50%);
    
}
.css-sgsh8p {
    height: 550px !important;
    width: 90% !important;
    margin-top: 5% !important;
    font-size: 10px !important;
}
.MuiDataGrid-main .MuiDataGrid-columnHeaders{
    background-image: linear-gradient(to left top, #272138, #272138, #272138, #272138, #272138)!important;

}

/* .rd3t-tree-container {
    width: 71%;
    height: 100%;
    margin: auto;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-right: 25vw;
} */


.membertree {
    min-height: 100vh;
    display: block;
    margin: auto;
}

.treeContainer {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    overflow-x: auto;
    margin-left: 5%;
}

/* custom-tree.css */

.node__root>circle {
    fill: #5248ba;
    stroke: transparent;
}

.node__branch>circle {
    fill: green;
    stroke: transparent;
}

.node__leaf>circle {
    fill: black;
    stroke: transparent;
}

.selectBoxDiv{
display: flex;
justify-content: center;
/* background-color: red; */
}
.changePasscode{
    /* background: linear-gradient(180deg, #000000d1, #00000000,#000000c7);  */
    
    margin: auto;
    display: flex;
    justify-content: center;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 60vw;
    margin-top: 6%;
    background: #E9EDF0;
    border: 0.5px solid #FEFEFF;
    box-shadow: 10px 10px 24px rgb(166 180 200 / 70%), -12px -12px 20px rgb(255 255 255 / 80%);
    border-radius: 40px;
    /* padding: 2%; */
    padding: 5%;
   
}


.changePasscode__heading{
    font-style: normal;
    font-weight: 700;
    font-size: 1.5vw;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #646E82;
}

.changePasscode__inputs{
    background: #F5F5FA;
    width: 65%;
    padding: 2%;
    border: none;
    box-shadow: inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 4px rgb(170 170 204 / 25%), inset 5px 5px 10px rgb(170 170 204 / 50%), inset -5px -5px 10px #ffffff;
    border-radius: 40px;
    height: 0.5vw;
    margin: 1vw 1vw;
}



.changePasscode__btn{
    font-size: 1vw;
    color: #FFFFFF;
    font-weight: 700;
    padding: 1% 6%;
    background-color: #0fd6eb;
    border: none;
    border-radius: 20px;
    background: #615AA1;
    box-shadow: -10px -10px 25px rgb(255 255 255 / 85%), 10px 10px 30px rgb(55 77 109 / 50%);
    border-radius: 50px;
    margin-top: 2%;
    cursor: pointer;
}


@media screen and (min-device-width: 0px) and (max-device-width: 1000px){
    .changePasscode{
        width: 75vw !important;
    margin-top: 10vw !important;
    height: 60vw !important;
    }

    .changePasscode__heading {
        font-size: 4vw;
        margin: 2vw 0vw;
    }
    .changePasscode__inputs {
        font-size: 3vw;
        height: 7vw !important;
        margin: 2vw 0vw;
    }

    .changePasscode__btn {
        font-size: 4vw;
        margin-top: 4%;
    }
}
.hide-scrollbars {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  
  .hide-scrollbars::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

  .text-shadow {
    text-shadow: 3px 3px 3px rgba(0, 0, 0, .5);
  }

  .text-shadow-red {
    text-shadow: 2px 2px 2px rgba(226, 12, 12, 0.589);
  }
@tailwind base;
@tailwind components;
@tailwind utilities;



/* https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Bree+Serif&family=Rubik+Doodle+Shadow&family=Salsa&display=swap */
* {
  font-family: 'Urbanist', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

body {
  margin: 0px;
  background: #F5F5F5;
  /* max-width: 1760px !important; */
  background-repeat: no-repeat;
  background-size: 100%;
}

.container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 10px;
}

.token {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 20px;
  margin-top: 50px;
  transition: all 0.2s;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
}

.jcNZzC {
  transition: background-color 0.2s ease-in-out 0s;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  padding: 24px 16px;
}

:root {
  --primary: #6948ff;
  --secondary: #553bcb;
  --darkgrey: #22262a;
  --lightgrey: #d3d3d3;
  --grey: #646E82;
  --violet: #615AA1;
  --headingcolor: #646E82;
  --btncolor: linear-gradient(139deg, rgba(10, 251, 252, 1) 1%, rgba(12, 235, 244, 1) 9%, rgba(18, 192, 223, 1) 31%, rgba(27, 123, 190, 1) 88%, rgba(28, 117, 187, 1) 91%)
}

.gradientText{
  background: #33DA87;
  background: linear-gradient(87deg, #33DA87 1.72%, #1E5FC1 94.54%);
  -webkit-background-clip: text;
-webkit-text-fill-color: transparent;

}


.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
  
}

.slider {
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.slider .slide-track {
	animation: scroll 30s linear infinite;
	display: flex;
	width: calc(250px * 14);
}

.slider .slide {
}

.slider .slide img {
	border-radius: 10px;
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-250px * 7));
	}
}

.gradientCard{
  border-radius: 38px;
border: 9.05px solid #32D589;

background: rgba(255, 255, 255, 0.10);

-webkit-backdrop-filter: blur(74.65984344482422px);

        backdrop-filter: blur(74.65984344482422px);
}

.gradientCard2{

/* background: rgba(255, 255, 255, 0.10); */
border-radius: 38px;

-webkit-backdrop-filter: blur(74.65984344482422px);

        backdrop-filter: blur(74.65984344482422px);
  background: linear-gradient(87deg, #33DA87 1.72%, #1E5FC1 94.54%);
}

.gbbb{
  background: linear-gradient(87deg, #33DA87 1.72%, #1E5FC1 94.54%);
  -webkit-backdrop-filter: blur(74.65984344482422px);
          backdrop-filter: blur(74.65984344482422px);

}

.currentValue{
  border-radius: 38px;
border: 2px solid #32D589;
background: rgba(255, 255, 255, 0.10);
-webkit-backdrop-filter: blur(74.65984344482422px);
        backdrop-filter: blur(74.65984344482422px);
}
