.navbar {
  color: white;
  background-color: #030205;
  display: flex;
  align-items: center;
  padding: 1%;
  margin: auto;
  width: 75vw;
  background: linear-gradient(180deg, #FFFFFF -17.81%, #EBF1FF 139.04%);
  box-shadow: 16px 11px 25px 2px rgb(203 204 206 / 60%);
  border-radius: 20px;
  margin: 1% auto;
  height: 4vw;
  display: flex;
  justify-content: space-between;
}

.navbar__hamburgerIcon {
  position: absolute !important;
  left: 5px !important;
  color: black !important;
  display: none !important;
}

.navbar div p {
  color: #473B8C;
  margin-left: 30%;
  text-transform: uppercase;
  font-size: 1.4vw;
}

.navbar__date {
  color: black;
}

.navbar__rightBox {
  display: flex;
  margin-left: -10%;

}

.navbar__rightBox div {
  margin: 0% 2%;

}

.referAndWalletButtons {

  margin-top: 6%;
  text-align: center;
  display: flex !important;
  width: 75% !important;
}

.referButton {
  background: linear-gradient(6.78deg, #443D85 -2.09%, #7770BD 122.05%);
  box-shadow: 8px 7px 12px 2px rgb(203 204 206 / 80%);
  border-radius: 9px !important;
  margin-top: 6%;
  text-align: center;
  display: flex !important;
  width: 8vw !important;
  color: #FFFFFF !important;
  height: -moz-fit-content;
  height: fit-content;
}

.navbar__profileImg {
  width: 96%;
  box-shadow: 8px 9px 12px 2px rgb(203 204 206 / 50%);
  border-radius: 50%;
}

.walletButton {
  height: fit-content;
}

.navbar__search {
  align-items: center;
  display: flex;
  margin: 0% 0%;
}

.navbar__searchInput {
  width: 200px;
  border: 1px solid white;
  border-radius: 10px;
  color: white;
  background: black;
}

.navbar__searchInput::placeholder {
  color: white;
}

.navbar__button {
  background: linear-gradient(139deg,
      rgba(10, 251, 252, 1) 1%,
      rgba(12, 235, 244, 1) 9%,
      rgba(18, 192, 223, 1) 31%,
      rgba(27, 123, 190, 1) 88%,
      rgba(28, 117, 187, 1) 91%);
  color: #ffff;
  padding: 5px 10px;
  font-size: 0.8rem;
  border-radius: 10px;
  text-decoration: none;
  margin: 0% 4%;
}

.navbar__Walletbutton {
  background: linear-gradient(139deg,
      rgba(10, 251, 252, 1) 1%,
      rgba(12, 235, 244, 1) 9%,
      rgba(18, 192, 223, 1) 31%,
      rgba(27, 123, 190, 1) 88%,
      rgba(28, 117, 187, 1) 91%);
  color: #ffff;
  padding: 5px 10px;
  font-size: 0.8rem;
  border-radius: 10px;
  text-decoration: none;
  /* position: absolute;
    right: 6%; */
}

.navbar__selectOption {
  width: 150px;
  color: white;
  font-size: 20px;
  background-color: black;
}

.navbar__heading {
  margin: 0% 7%;
  font-size: 25px;
}

.hamburgerMenu {
  display: none !important;
}

.hamburgerMenu:hover {
  cursor: pointer !important;
}


.css-17fijvd {
  position: Fixed;
  top: 50%;
  left: 50%;
  right: 10%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50%;
  /* background-image: linear-gradient(to right, #a8caba 0%, #5d4157 100%); */
  /* background: linear-gradient(to top, #30cfd0 0%, #330867 100%); */
  height: 300px;
  /* background: rgb(33 37 40) !important; */
  /* border: 2px solid #000; */
  /* box-shadow: 10px 10px 20px 10px white !important; */
  /* padding: 32px; */
  /* color: white; */
  background: #E9EDF0;
  border: 0.5px solid #FEFEFF;
  box-shadow: 10px 10px 24px rgba(166, 180, 200, 0.7), -12px -12px 20px rgba(255, 255, 255, 0.8);
  border-radius: 40px;
}

.navbar__rightBox__date {
  display: none;
}


@media screen and (min-device-width: 0px) and (max-device-width: 1000px) {
  .navbar {
    background: none;
    margin-top: 8%;
    justify-content: space-evenly;
    box-shadow: none;
  }

  .navbar div p {
    display: none;
    font-size: 0.8rem;
  }

  .navbar__date {
    display: none;
  }

  .navbar__rightBox div {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .navbar__rightBox__date {
    display: block !important;
    margin: 0 !important;
    margin-top: 5% !important;

  }

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    /* width: fit-content !important; */
    font-size: 3vw !important;

  }


  .referButton {
    width: 30vw !important;
  }

  .navbar__profileImg {
    margin-top: 1vw;

  }

  .navbar__hamburgerIcon {
    display: block !important;
    width: 9vw !important;
    height: 11vw !important;
  }

}