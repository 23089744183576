@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Urbanist&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@300&family=Urbanist:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Bree+Serif&family=Rubik+Doodle+Shadow&family=Salsa&display=swap');



/* https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Bree+Serif&family=Rubik+Doodle+Shadow&family=Salsa&display=swap */
* {
  font-family: 'Urbanist', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

body {
  margin: 0px;
  background: #F5F5F5;
  /* max-width: 1760px !important; */
  background-repeat: no-repeat;
  background-size: 100%;
}

.container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 10px;
}

.token {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 20px;
  margin-top: 50px;
  transition: all 0.2s;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
}

.jcNZzC {
  transition: background-color 0.2s ease-in-out 0s;
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  padding: 24px 16px;
}

:root {
  --primary: #6948ff;
  --secondary: #553bcb;
  --darkgrey: #22262a;
  --lightgrey: #d3d3d3;
  --grey: #646E82;
  --violet: #615AA1;
  --headingcolor: #646E82;
  --btncolor: linear-gradient(139deg, rgba(10, 251, 252, 1) 1%, rgba(12, 235, 244, 1) 9%, rgba(18, 192, 223, 1) 31%, rgba(27, 123, 190, 1) 88%, rgba(28, 117, 187, 1) 91%)
}

.gradientText{
  background: #33DA87;
  background: linear-gradient(87deg, #33DA87 1.72%, #1E5FC1 94.54%);
  -webkit-background-clip: text;
-webkit-text-fill-color: transparent;

}


.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
  
}

.slider {
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.slider .slide-track {
	-webkit-animation: scroll 30s linear infinite;
	animation: scroll 30s linear infinite;
	display: flex;
	width: calc(250px * 14);
}

.slider .slide {
}

.slider .slide img {
	border-radius: 10px;
}

@-webkit-keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-250px * 7));
	}
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-250px * 7));
	}
}

.gradientCard{
  border-radius: 38px;
border: 9.05px solid #32D589;

background: rgba(255, 255, 255, 0.10);

backdrop-filter: blur(74.65984344482422px);
}

.gradientCard2{

/* background: rgba(255, 255, 255, 0.10); */
border-radius: 38px;

backdrop-filter: blur(74.65984344482422px);
  background: linear-gradient(87deg, #33DA87 1.72%, #1E5FC1 94.54%);
}

.gbbb{
  background: linear-gradient(87deg, #33DA87 1.72%, #1E5FC1 94.54%);
  backdrop-filter: blur(74.65984344482422px);

}

.currentValue{
  border-radius: 38px;
border: 2px solid #32D589;
background: rgba(255, 255, 255, 0.10);
backdrop-filter: blur(74.65984344482422px);
}