/* .rd3t-tree-container {
    width: 71%;
    height: 100%;
    margin: auto;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-right: 25vw;
} */


.membertree {
    min-height: 100vh;
    display: block;
    margin: auto;
}

.treeContainer {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    overflow-x: auto;
    margin-left: 5%;
}

/* custom-tree.css */

.node__root>circle {
    fill: #5248ba;
    stroke: transparent;
}

.node__branch>circle {
    fill: green;
    stroke: transparent;
}

.node__leaf>circle {
    fill: black;
    stroke: transparent;
}
